import * as React from "react";
import { NextPage } from "next";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useRouter } from "next/router";
import {
  idTokenRolesKey,
  Role,
} from "../common/components/utilities/auth/roles";

export const Index: NextPage = () => {
  const { user, logout } = useAuth0();
  const router = useRouter();

  React.useEffect(() => {
    if (user) {
      const userRoles = user[idTokenRolesKey];
      if (userRoles.includes(Role.Admin)) {
        router.push("/tenants");
      }
    }
  }, [user, router]);

  if (user) {
    const userRoles = user[idTokenRolesKey];

    if (!userRoles.includes(Role.Admin)) {
      return (
        <>
          <p>
            You don&apos;t have the correct permissions. Please log in with a
            different account.
          </p>
          <button
            onClick={() =>
              logout({ logoutParams: { returnTo: process.env.hostName } })
            }
          >
            Log out
          </button>
        </>
      );
    }
  }
  return <p>Loading...</p>;
};

export default withAuthenticationRequired(Index);
